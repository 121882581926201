<template>
<div class="home">
    <div class="flex justify-center">
        <div class="home-top  w-full mw-1920 pt-200" v-if="!loader" style="min-height:400px">
            <div class="home-top-items">
                <h1 data-aos="fade-up"> {{leasing.data[`title_${$i18n.locale}`]}} </h1>
                <p data-aos="fade-up">{{leasing.data[`description_${$i18n.locale}`]}}</p>
                <div class="home-top-buttons" data-aos="fade-up">
                    <a :href="leasing.data.button1_url" >
                        <button class="button "> <span> {{leasing.data[`button1_title_${$i18n.locale}`]}} </span> </button>
                    </a>
                    <button class="button blue" @click="modal=true"> <span>{{leasing.data[`button2_title_${$i18n.locale}`]}}</span> </button>
                </div>
            </div>
             <div class="top-images"> 
                <div class="transformer opacity-anime">  
                    <img :src="`https://avior.prestigeautofl.com${leasing.files.Image1}`" class=" z-10 big-bg1 pr-img" data-aos="opacity-anime2" alt="">
                    <img :src="`https://avior.prestigeautofl.com${leasing.files.Image2}`" class=" z-10  big-bg2 pr-img" data-aos="opacity-anime1" alt="">
                    <img :src="`https://avior.prestigeautofl.com${leasing.files.Image3}`" class=" z-10  big-bg3 pr-img" data-aos="opacity-anime-car2" alt="">
                    <div class="home-top-clouds">
                        <img :src="`https://avior.prestigeautofl.com${leasing.files.ImageClouds}`" class="big-bg-clouds1  " alt="">
                        <img :src="`https://avior.prestigeautofl.com${leasing.files.ImageClouds}`" class="big-bg-clouds2  " alt="">
                        <img :src="`https://avior.prestigeautofl.com${leasing.files.ImageClouds}`" class="big-bg-clouds3  " alt="">
                        <img :src="`https://avior.prestigeautofl.com${leasing.files.ImageClouds}`" class="big-bg-clouds4  " alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="top-images"> 
                <img src="@/assets/img/big-bg2-bg.svg" class="big-bg-bg desktop" data-aos="opacity-anime1" alt="">
                <img src="@/assets/img/big-bg1.svg" class="desktop z-10 big-bg1 pr-img" data-aos="opacity-anime2" alt="">
                <img src="@/assets/img/big-bg2.svg" class="desktop z-10  big-bg2 pr-img" data-aos="opacity-anime1" alt="">
                <img src="@/assets/img/big-bg4.svg" class="desktop z-10  big-bg3 pr-img" data-aos="opacity-anime-car2" alt="">
                <img src="@/assets/img/leasingtop550.svg" class="phone550 pr-img" alt="">
                <img src="@/assets/img/leasingtop768.svg" class="phone768 pr-img" alt="">
                <div class="home-top-clouds">
                    <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds1  " alt="">
                    <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds2  " alt="">
                    <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds3  " alt="">
                    <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds4  " alt="">
                </div>

            </div> -->
        </div>
        <div class="home-top  w-full mw-1920 pt-200" v-if="loader" style="min-height:400px">
            <div class="home-top-items ">
                <el-skeleton :rows="6" animated />
            </div>
        </div>
    </div>
    <Linkes :data="leasing.data&&leasing.data.links?leasing.data.links:[]" :files="leasing.files" :loader="loader" imgText="linksImage" />

    <div class="flex justify-center w-full" v-if="leasing.data">
        <div class="technical-info mw-1920 w-full" data-aos="fade-up">
            <h1 > {{leasing.data[`technique_title1_${$i18n.locale}`]}} </h1>
            <h2>{{leasing.data[`technique_title2_${$i18n.locale}`]}}</h2>
            <p>{{leasing.data[`technique_text_${$i18n.locale}`]}}</p>
        </div>
    </div>
    <div class="flex justify-center" v-if="leasing.data&&leasing.data.types">
        <div class="leasing-type-list w-full mw-1440">
            <div class="leasing-card-item" v-for="(item, i) in leasing.data.types" :key="i" data-aos="fade-up">
                <LeasingCard :data="item" :img="leasing.files[`types${i}`]" />
            </div>

        </div>
    </div>
    <Why data-aos="fade-up" />
    <WriteUs data-aos="fade-up" />


    <div class="modal" :class="modal?'active':''">
        <div @click="modal=false" class="modal-cover"></div>
        <div class="contact-card" v-loading="smsLoading">
            <el-form ref="smsForm" :model="sms" :rules="smsRules">
                <h1> {{$t('coming_soon')}} </h1>
                <el-form-item prop="name">
                    <el-input :placeholder="$t('enter_mail')" class="contact-card-input" v-model="sms.name"></el-input>
                </el-form-item>
                <el-form-item prop="text">
                    <el-input type="textarea" :rows="5" class="contact-card-text-area" :placeholder="$t('enter_text')" v-model="sms.text"> </el-input>
                </el-form-item>
                <div class="flex justify-center">
                    <button class="button" type="button" @click="SendSms"> {{$t('contact_send')}} </button>
                </div>
            </el-form>
        </div>
    </div>

</div>
</template>

<script>
 import LeasingCard from '../../components/LeasingCard.vue'
 import Why from '../../components/Why.vue'
 import WriteUs from '../../components/WriteUs.vue'
 import Linkes from '../../components/linkes.vue'
 export default {
     name: 'Leasing',
     components: {
         LeasingCard,
         Why,
         WriteUs,
         Linkes
     },
     data() {
         return {
             LeasingTypeList: [{
                     title: 'Легковий автотранспорт',
                     payment_type: ['Аванс від 0%', 'Сезонний графік платежів', 'Фінансування авто любого класу'],
                     img: require('@/assets/img/leasingTypeCar.svg')
                 },
                 {
                     title: 'Вантажний автотранспорт',
                     payment_type: ['Аванс від 0%', 'Сезонний графік платежів', 'Фінансування авто любого класу'],
                     img: require('@/assets/img/FreightCar.svg')
                 },
                 {
                     title: 'Сільськогосподарська техніка',
                     payment_type: ['Аванс від 0%', 'Сезонний графік платежів', 'Фінансування авто любого класу'],
                     img: require('@/assets/img/Agricultural Car.svg')
                 },
                 {
                     title: 'Будівельна техніка',
                     payment_type: ['Аванс від 0%', 'Сезонний графік платежів', 'Фінансування авто любого класу'],
                     img: require('@/assets/img/BuildingCar.svg')
                 },
             ],
             sms: {
                name: '',
                text: ''
            },
            modal: false,
            smsLoading: false,
            smsRules: {
                name: [{
                     required: true,
                     type:'email',
                     message: 'Введіть вашу електронну пошт!',
                     trigger: 'change'
                }],
                text: [{
                    required: true,
                    message: this.$t('input_text_please'),
                    trigger: 'change'
                }],
            }
         }
     },
     computed: {
         leasing() {
             return this.$store.state.Leasing
         },
         loader() {
             return this.$store.state.loader
         }
     },
     methods: {
         SendSms() {
             this.$refs.smsForm.validate((valid) => {
                 if (valid) {
                     this.$notify({
                         title: 'Успех',
                         message: 'Ваш запрос был успешно отправлен!',
                         type: 'success'
                     });
                     this.smsLoading = false
                     this.$refs.smsForm.resetFields();
                     this.modal=false
                 } else {
                     return false;
                 }
             });
         }
     },
     mounted() {}

 }
</script>

<style lang="scss">
.big-bg1 {
    position: absolute;
    z-index: 2;
}

.big-bg2 {
    position: relative;
    z-index: 1;
}

.big-bg3 {
    position: absolute;
    z-index: 1;
}

.technical-info {
    padding: 0px 120px;

    h1 {
        font-family: Stem-Medium;
        font-size: 40px;
        line-height: 42px;
        font-feature-settings: 'tnum'on, 'lnum'on;
        color: var(--other-black);
    }

    h2 {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        margin: 0;
        margin-top: 20px;
        color: var(--white-black);

    }

    p {
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 27px;
        font-feature-settings: 'tnum'on, 'lnum'on;
        margin-top: 40px;
        color: var(--white-black);

    }

}

.leasing-type-list {
    padding: 20px 80px;
    display: flex;
    flex-wrap: wrap;

    .leasing-card-item {
        width: 50%;
        padding: 40px;
        display: flex;
    }
}

@media screen and(max-width:1100px) {
    .technical-info {
        padding: 20px 20px;
    }

    .leasing-type-list {
        padding: 20px 0px;

        .leasing-card-item {
            padding: 20px;
        }
    }

}

@media screen and(max-width:768px) {

    .leasing-type-list {
        .leasing-card-item {
            display: flex;
            justify-content: center;
            width: 100%;

        }
    }

    .technical-info {

        h1 {
            font-size: 24px;
            line-height: 28px;
            text-align: center;
        }

        h2 {
            font-size: 0.875rem;
            line-height: 1rem;
            text-align: center;

        }

        p {
            font-size: 0.875rem;
            line-height: 24px;
            text-align: center;

        }

    }

}
</style>
