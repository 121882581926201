<template>
<div class="flex justify-center">
    <div class="why mw-1920" v-if="leasing.data">
        <h1 class="title-h1"> {{leasing.data[`whyText_ua`]}} </h1>
        <div class="why-items w-full">
            <div class="why-item">
                <div class="w-full" v-for="(item, i) in leasing.data.why" :key="i">
                <h2> {{item[`title_${$i18n.locale}`]}} </h2>
                <p>{{item[`description_${$i18n.locale}`]}}</p> 
                </div>
            </div>
            <div class="why-item why-img">
                <div class="why-img-anime">
                    <img class="why-img1" :src="`https://avior.prestigeautofl.com${leasing.files.whyImage1}`"  data-aos="run-anime1" alt="">
                    <img class="why-img2" :src="`https://avior.prestigeautofl.com${leasing.files.whyImage2}`" data-aos="run-anime2" alt="">
                    <img class="why-img3" :src="`https://avior.prestigeautofl.com${leasing.files.whyImage3}`" data-aos="opacity-anime1" alt="">
                </div>
                <!-- <div class="why-img-anime">
                    <img class="why-img1" src="@/assets/img/why1.svg"  data-aos="run-anime1" alt="">
                    <img class="why-img2" src="@/assets/img/why2.svg" data-aos="run-anime2" alt="">
                    <img class="why-img3" src="@/assets/img/why3.svg" data-aos="opacity-anime1" alt="">
                </div> -->
                
            </div>
        </div>
    </div>
</div>
</template>
<script> 

export default {
    computed:{
        leasing() {
             return this.$store.state.Leasing
         }
    }
}
</script>

<style lang="scss">
.why{
    padding: 100px 100px;
    width: 100%;
    h1{
        padding: 0px 20px;
    }
    .why-items{
        display: flex;
        flex-wrap: wrap;
    }
    .why-item{
        width: 50%;
        padding:0px 20px;
        h2{            
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem; 
            color: var(--other-black);
            margin: 0;
            margin-top: 40px;

        }
        p{
            margin-top: 20px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 24px;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--white-black);
        }
    }


}
.why-img{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    img{
        width: 80%;
    }
}
.why-img-anime{
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    width: 100%;
}
.why-img1{
    position: relative;
    z-index: 1;
}
.why-img2{
    position: absolute;
    right: 0;
    z-index: 0;
    
}
.why-img3{
    position: absolute;
    right: 0;
    z-index: 2;
}
@media screen and(max-width:1100px) {
  .why{
    padding: 40px 0px;
    }
}

@media screen and(max-width:768px) {
    .why{ 
    h1{ 
        font-size: 24px;
        line-height: 28px;
    }
    .why-items{
        display: flex;
        flex-wrap: wrap;
    }
    .why-item{
        width: 100%;
        padding:30px 20px;
        h2{             
            font-size: 1.125rem;
            line-height: 1.25rem;
            margin-top: 30px;

        }
        p{
           font-size: 0.875rem;
           line-height: 24px;
        }
    }


}
.why-img{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    img{
        width: 100%;
    }
}
    
}
</style>