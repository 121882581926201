<template>
<div class="leasing-card">
    <div>
        <h1> {{data&&data[`title_${$i18n.locale}`] ? data[`title_${$i18n.locale}`]:'' }} </h1>
        <p v-if="data&&data[`description1_${$i18n.locale}`]">{{data[`description1_${$i18n.locale}`] }}</p>
        <p v-if="data&&data[`description2_${$i18n.locale}`]">{{data[`description2_${$i18n.locale}`] }}</p>
        <p v-if="data&&data[`description3_${$i18n.locale}`]">{{data[`description3_${$i18n.locale}`] }}</p>
    </div>
    <div class="leasing-card-link">
        <a :href="data&&data.url?data.url:'/leasing/technique'">
            <button class="leasing-card-button"> {{data&&data[`button_${$i18n.locale}`]? data[`button_${$i18n.locale}`]:'Список' }} </button>
        </a>
    </div>

    <div class="leasing-card-img" v-if="img">
        <img :src="`https://avior.prestigeautofl.com${img}`" alt="">
    </div>
</div>
</template>

<script>
export default {
    props: ['data', 'img']
}
</script>

<style lang="scss">
.leasing-card {
    padding: 40px 40px;
    max-width: 560px;
    width: 100%;
    background: var(--white-bg);
    box-shadow: 0px 6.43813px 17.1683px -8.58417px rgba(0, 72, 153, 0.12), 0px 9.65719px 30.0446px rgba(0, 72, 153, 0.05), 0px 12.8763px 51.505px 17.1683px rgba(0, 72, 153, 0.03);
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--other-black);
        position: relative;
        z-index: 1;
    }

    p {
        font-family: Stem-Regular;
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--white-black);
        margin-top: 20px;
        position: relative;
        z-index: 1;

    }

    .leasing-card-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 0;

        img {
            width: 100%;
        }
    }
}

.leasing-card-link {
    margin-top: 40px;
}

.leasing-card-button {
    cursor: pointer !important;

    padding: 12px 20px;
    font-family: Stem-Medium;
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--white-color);
    border: 1px solid #00B5EC;
    ;
    background: #00B5EC;
    border-radius: 4px;
    min-width: 150px;
    outline: none;
    position: relative;
    z-index: 1;
    transition: all 0.2s ease-in;

    &:hover {
        background: unset;
        color: var(--color-blue);
    }
}

@media screen and(max-width:1440px) {
    .leasing-card {
        padding: 40px;
    }
}

@media screen and(max-width:768px) {
    .leasing-card {
        padding: 20px;
        max-width: 320px;

        h1 {
            font-size: 1rem;
            line-height: 1.125rem;
        }

        p {
            font-size: 0.75rem;
            line-height: 0.875rem;

        }

    }

    .leasing-card-button {
        font-size: 0.875rem;
        line-height: 1rem;
        min-width: 100px;

    }

}
</style>
