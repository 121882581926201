<template>
<div class="contact-card" v-loading="smsLoading">
    <el-form ref="smsForm" :model="sms" :rules="smsRules">
        <h1> {{$t('coming_soon')}} </h1>
        <el-form-item prop="name">
            <el-input :placeholder="$t('enter_mail')" class="contact-card-input" v-model="sms.name"></el-input>
        </el-form-item>
        <el-form-item prop="text">
            <el-input type="textarea" :rows="5" class="contact-card-text-area" :placeholder="$t('enter_text')" v-model="sms.text"> </el-input>
        </el-form-item>
        <div class="flex justify-center">
            <button class="button" type="button" @click="SendSms"> {{$t('contact_send')}} </button>
        </div>
    </el-form>
</div>
</template>

<script>
 export default {
     data() {
         return {
             sms: {
                 name: '',
                 text: ''
             },
             smsLoading: false,
             smsRules: {
                 name: [{
                     required: true,
                     type:'email',
                     message: this.$t('input_mail'),
                     trigger: 'change'
                 }, 
                 
                 ],
                 text: [{
                     required: true,
                     message: this.$t('input_text_please'),
                     trigger: 'change'
                 }],
             }
         }
     },
     methods: {
         SendSms() {

             this.$refs.smsForm.validate((valid) => {
                 if (valid) {
                     this.$notify({
                         title: 'Успех',
                         message: 'Ваш запрос был успешно отправлен!',
                         type: 'success'
                     });
                     this.smsLoading = false
                     this.$refs.smsForm.resetFields();
                 } else {
                     return false;
                 }
             });
         }
     }
 }
</script>

<style lang="scss">
.contact-card {
    padding: 40px;
    max-width: 570px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
    border-radius: 10px;
    background: var(--white-bg);

    h1 {
        font-family: Stem-Regular;
        font-size: 20px;
        line-height: 1.5rem;
        color: var(--other-black);
    }

    .contact-card-input {

        width: 100%;

        .el-input__inner {
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 18px 30px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            height: auto;
            color: var(--white-black);
            margin-top: 25px;
        }
    }

    .contact-card-text-area {
        margin-top: 20px;
        width: 100%;

        .el-textarea__inner {
            padding: 18px 30px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            height: auto;
            color: var(--white-black);
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }

    .is-error {
        .el-textarea__inner, .el-input__inner{
            color: #F56C6C !important;
            border: 1.5px solid #F56C6C !important;
        }

         
    }

    button {
        margin-top: 40px;
    }

}

@media screen and(max-width:768px) {
    .contact-card {
        padding: 25px;
        max-width: 570px;

        h1 {
            font-size: 1rem;
            line-height: 1.125rem;
        }

        .contact-card-input {
            .el-input__inner {
                padding: 15px 20px;
                margin-top: 25px;
                font-size: 1rem;
                line-height: 1.125rem;
            }
        }

        .contact-card-text-area {
            margin-top: 20px;
            width: 100%;

            .el-textarea__inner {
                font-size: 1rem;
                line-height: 1.125rem;
                padding: 15px 20px;
            }
        }
    }
}
</style>
