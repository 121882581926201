<template>
    <div class="flex justify-center">
        <div class="sistems mw-1920" v-if="!loader">
            <div class="sistem-item btn-4" v-for="(item,i) in data" :key="i" data-aos="fade-up" data-aos-offset="80" data-aos-easing="ease-out-cubic"  :data-aos-delay="i*200" >
                
                <div class="img-div">
                    <img :src="`https://avior.prestigeautofl.com${files[imgText+item.id]}`" alt="">
                </div>
                <p>{{item[`title_${$i18n.locale}`]  }}   </p>
            </div>   
             
        </div> 
        <div class="sistems mw-1920" v-if="loader">
            <div class="sistem-item btn-4" v-for="i in 4" :key="i"   >
                <el-skeleton style="width: 100%;" animated>
                <template slot="template">
                <div class="link-scleton">
                <el-skeleton-item variant="image" class="scleton-link-img"   />
                <div style="padding: 14px;" class="scleton-link-text">
                    <el-skeleton-item variant="p" style="width: 100%" />
                </div>
                </div>
                </template>         
            </el-skeleton>   
            </div>   
        </div> 
    </div>
</template>
<script> 

export default {
    name:'linkes',
    props:['data', 'files', 'imgText', 'loader'],
    
}
</script>

<style lang="scss">
.sistems {
    padding: 150px 120px; 
    display: flex;
    justify-content: center;

    .sistem-item {
        margin-top: 20px;
        margin-right: 140px;
        max-width: 200px;
        cursor: pointer;
        a{
            text-decoration:  unset !important;
        }

        p {
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem;
            text-align: center;
            color: var(--white-black);

        }

        .img-div {
            width: 100%;
            height: 110px;
            display: flex;
            justify-content: center;
            padding: 20px;
            img{
                max-width: 100%;
            }

        }
    }

    .sistem-item:last-child {
        margin-right: 0px;
    }

    .sistem-item:hover {
        p {
            color: var(--color-blue);
        }

        ;

    }
}
.link-scleton{
        display: block; 
        .scleton-link-img{
            width: 120px;
            height: 120px;
        }
    }
    .scleton-link-text{
        flex: 1;
    }
@media screen and(max-width:1100px) {
    .sistems {
        padding: 100px 20px;

        .sistem-item {
            margin-right: 40px;
        }
    }
}

@media screen and(max-width:668px) {
    .sistems {
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .sistem-item {
            margin-top: 60px;
            margin-right: 0px;
            width: 100%;
            max-width: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            a{
                display: flex;
                align-items: center;
            }

            p {
                font-size: 0.875rem;
                line-height: 1rem;
                text-align: left;
                padding-left: 10px;

            }

            .img-div {
                min-width: 130px;
                width: 130px;
                height: 90px;
                display: flex;
                justify-content: flex-start;
                padding: 10px 20px 10px 10px;

            }
        }
    }
    .link-scleton{
        display: flex;
        align-items: center;
        .scleton-link-img{
            width: 60px;
            height: 60px;
        }
    }
    .scleton-link-text{
        flex: 1;
    }

}

</style>