<template>
    <div class="write-us">
        <div class="write-us-item" v-if="leasing.data">
            <h1 class="title-h1"> {{leasing.data[`about_title_${$i18n.locale}`]}} </h1>
            <div class="phone-info">
                <p class="phone-number">{{leasing.data.phone}}</p>
                <p class="phone-nunber-text">{{leasing.data[`phone_title_${$i18n.locale}`]}}</p>
            </div>
            <div class="email-info">
                <p v-for="(item, i) in leasing.data.mails" :key="i">Служба підтримки: <span>{{item}}</span></p> 
            </div>
            <div class="adress">
                <p> {{leasing.data[`adress_${$i18n.locale}`]}}</p>
            </div>
        </div>
        <div class="write-us-item">
            <ContactCard />
        </div>
    </div>
</template>
<script>

import ContactCard from './ContactCard.vue'

export default {
    components:{
        ContactCard
    },
    computed:{
        leasing() {
             return this.$store.state.Leasing
         }
    }
}
</script>

<style lang="scss">

.write-us{
    padding: 100px 100px;
    display: flex;
    background: var(--bg-top);
    .write-us-item{
        padding: 20px;
        width: 50%;
    }
    .phone-info{
        margin-top: 60px;
        .phone-number{
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem; 
            color: var(--other-black);
        }
        .phone-nunber-text{
            font-family: Stem-Medium;
            font-size: 0.875rem;
            line-height: 1rem; 
            color: var(--white-black);
            margin-top: 10px;
        }
    }
    .email-info{
        margin-top: 50px;
        p{
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--white-black);
            margin-top: 10px;
            span{
                color: var(--other-black);
            }
        }
    }
    .adress{
        margin-top: 60px;
        p{
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem; 
            color: var(--other-black);
        }
    }
}
@media screen and(max-width:1100px) {
    .write-us{
    padding: 100px 0px;
    }
}
@media screen and(max-width:768px) {
    .write-us{
    padding: 30px 0px; 
    flex-wrap: wrap;
    h1{
        text-align: center;
        font-size: 24px;
        line-height: 28px;
    }
    .write-us-item{
        padding:40px 20px;
        width: 100%;
    }
    .phone-info{
        margin-top: 60px;
        .phone-number{
            font-size: 1rem;
            line-height: 1.125rem;
        }
        .phone-nunber-text{
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }
    .email-info{
        margin-top: 40px;
        p{
            font-size: 1rem;
            line-height: 1.125rem; 
        }
    }
    .adress{
        margin-top: 40px;
        p{
            font-size: 1rem;
            line-height: 1.125rem; 
        }
    }
}
    
}

</style>